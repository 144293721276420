<template>
  <div id="app">
    <BHeader></BHeader>
    <main class="pt100">
      <router-view />
    </main>
  </div>
</template>

<script>
import BHeader from './components/Header'

export default {
  components: {
    BHeader
  }
}
</script>

<style lang="scss">
@import './assets/css/main.css';

.layout{
    min-width:1190px;
    background-color:#080909;
    position:relative;
    overflow:hidden;
}
.pt100{
    padding-top: 100px;
}
.default-color{color:#cfac4a}
.data-lazy{background-repeat:no-repeat;background-position:50%;background-size:contain}
.fl{float:left}
.fr{float:right}
.w,.w-middle{margin:0 auto};
.w-middle{width:1260px}
.clearfix:after,.clearfix:before{content:"";display:block;clear:both;line-height:0;visibility:hidden;height:0}
a:link{color:#333}
.fn-hide{display:none!important}
.qr-code-box{width:122px;height:140px;position:absolute;left:56%;top:86px;background-color:#fff4d3;margin-left:-68px;border-radius:8px;box-shadow:0 2px 20px rgba(0,0,0,.2);padding:13px 13px 29px;box-sizing:border-box}
.qr-code-box img{width:100%}
.qr-code-box:before{
    content:"";
    display:block;
    position:absolute;
    left:50%;
    top:-8px;
    -webkit-transform:translate(-50%);
    transform:translate(-50%);
    width:0;
    height:0;
    border-style:solid;
    border-width:0 11.365px 13.24px;
    border-color:transparent transparent #fff4d3
}
.qr-code-box .tips-title{
  color:#573c1f;
  font-size:16px;
  position:absolute;
  left:0;
  bottom:6px;
  text-align:center;
  line-height:22px;
  width:100%
}
.title{
  width: 100%;
  text-align: center;
}
</style>
