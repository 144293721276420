<template>
    <div class="layout">
        <div class="banner">
            <div class="handle">
                <img class="handle-tit-bg" src="../assets/images/handle-tit-bg.png" />
                <div class="handle-btn-box B-btn-box">
                    <button class="handle-btn">立即免费办理</button>
                    <div class="qr-code-box fn-hide B-qr-code-box">
                        <img src="../assets/images/hover-download-qrcode.png" alt="">
                        <span class="tips-title">扫码立即申请</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-section">
            <div class="card-top-bg">
                <div class="card-info-text">
                    <p>【 宝藏卡 】是上海巨潮信息技术有限公司旗下“趣买手”APP 推出的汇聚购物折扣、会员专属特权的综合权益卡!</p>
                    <p>卡内覆盖全网各大平台的海量优惠，无论衣食住行、吃喝玩乐，还是加油购物，宝藏卡都能够做你的一站式消费管家，帮你精打细算，笔笔省钱!</p>        
                </div>
            </div>
            <div class="card-box">
                <div class="title">
                    <img class="card-tit-bg" src='../assets/images/card-title-bg.png' />
                </div>
                <p class="title-sub-text">汇聚丰富会员权益，集合生活、购物、美食、加油、出行等尊享特权</p>
                <div class="card-rights-list">
                    <div class="card-rights-item">
                        <img class="rights-tit-bg" src="../assets/images/rights-tit1.png" />
                        <div class="rights-box">
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/tb-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">淘宝/天猫</p>
                                    <button class="rts-btn">最高返90%</button>
                                </div>
                            </div>
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/zb-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">首单0元购</p>
                                    <button class="rts-btn">千款爆品</button>
                                </div>
                            </div>
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/pdd-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">拼多多返现</p>
                                    <button class="rts-btn">最高50%</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-rights-item">
                        <img class="rights-tit-bg" src="../assets/images/rights-tit2.png" />
                        <div class="rights-box">
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/ele-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">饿了么外卖</p>
                                    <button class="rts-btn">天天20元</button>
                                </div>
                            </div>
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/kfc-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">KFC在线点餐</p>
                                    <button class="rts-btn">专享5折起</button>
                                </div>
                            </div>
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/xbk-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">星巴克</p>
                                    <button class="rts-btn">门店7折起</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-rights-item">
                        <img class="rights-tit-bg" src="../assets/images/rights-tit3.png" />
                        <div class="rights-box">
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/qg355-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">全国355城</p>
                                    <button class="rts-btn">加油7折起</button>
                                </div>
                            </div>
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/dd-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">滴滴打车</p>
                                    <button class="rts-btn">专享8折起</button>
                                </div>
                            </div>
                            <div class="rts-item">
                                <img class="rts-icon" src='../assets/images/qjdc-icon.png' />
                                <div class="rts-info">
                                    <p class="rts-text">青桔单车</p>
                                    <button class="rts-btn">月卡特卖</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        </div>
        <div class="shop-section">
            <div class="shop-content w">
                <div class="title">
                    <img class="shop-title-bg" src='../assets/images/shop-title-bg.png' />
                </div>
                <ul class="privi-big">
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon1.png">
                        </div>
                        <span>唯品会</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon2.png">
                        </div>
                        <span>淘宝购物</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon3.png">
                        </div>
                        <span>网易考拉</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon4.png">
                        </div>
                        <span>拼多多购物</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon5.png">
                        </div>
                        <span>宝藏自营</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon6.png">
                        </div>
                        <span>淘宝购物</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon7.png">
                        </div>
                        <span>饿了么</span>
                        <span class="pre-txt">单单返现</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon8.png">
                        </div>
                        <span>大众点评</span>
                        <span class="pre-txt">8.5折起</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                    <li class="item">
                        <div class="img-box">
                            <img src="../assets/images/shop-icon9.png">
                        </div>
                        <span>滴滴出行</span>
                        <span class="pre-txt">最高返90%</span>
                        <div class="scan-bg">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="decorate-box">
                <div class="img-box img-box-4">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAvVBMVEVHcEyshUTTo1a8kkqxiESgeju3jEelf0Keej/qxpb/yMjLnlLoxI7EoXPetoHClUvHmlCkfjvXrnjdqVnOrm6/mV98XCnjvojIoWeDYi/sv3XRq3S1kFi0jlishlCRbTXrwoKshUbduYDVlVWYczrQpnGKZjKkgEfjt3Prumm1kE/hu3++mVq9mVqviUuggE3nsF6kf0yqh0zLoF7arV/qw46XdUHetX3nw5XjtWanhEzow4+4lVbMoVvhsGk1uXbNAAAAP3RSTlMAg4ODg4ODg4KDAoODB4ODg4OCgw2Dg4ODgoMVGoODg4N6IgSDg4NvZoJqODBEXi6DP1J3Wn1lTFd6I3J2TkQSVm9HAAADcklEQVRIx53XiXKiQBAG4CCnKBGUuNEhMaAR5NCIB8Yj7/9Y2z0zHDGbZMe/tKyy6qMbnYu7uzKWa97dnChK3Fvtx2Lxfisn7y1ZU/z9yrqlaVlSIY6xFtbTQHPQGpCVKDZjlcWYGUvR0q7BrQEvbyqIQ7WKsRbEBDC9aXWmGrklinWOIUvBP9sDrHPr7D1BvNd13YHcgkmuo4YX4HAlNkO8JcUOtO4IY1JieBv+WrSypLOoju6vRX9twNyrvtjonh59CcO0KE4lhin3j2I4U5SqtOQTsRm51BQMqx+KDU831morCU5oz9caOhebkinHTIutYmamaVyD91MhbOV+Ex+v2zYtNyDuN5ckscaDOk4/D23TJUkRRdE2cP89smVZrnh8/Fw1SA6n3Xi8WCxgQ/kyZcw07sly5ePmWmCSJNp1X15exsjft4FlXq/4Z4aZbi5hVnBot9vdbs0/yOd7t9Jej2tZk7V6jJjBYYcW36XG3pvcutgVhmTV98mpXQWLjzHQexLU/4e7tUHT6vA6p+XdHnbQb/eLBh59ePzWTW9jc00bIFXL3W6tu6x1uMBi0ZL9cM15atu1ttktW0G0+4NpaLzSy65va7hCG+GK9n7p2Hbl7Qy/mybRPaTmZfen4lxuiSHWtratDoRrG8eXGzDLeLcqfyqIm4d0a4FtFddJb9NCbTO/CUz4maM+5L5/X2ugu0OAF14t2cZmIE4B09pUb12wm9fXPuX3dfPtEz/tmN4KqjsqPUJkWLnFcefiutnm8RVTe7BQthqZpntch7oB15peWjS08c4mI2BpOGf6BLY5rtxjHuLnR6vUiC/Z+QHCealPCTGv57hHx9dg0CrT2ZzPTw8PFX/ss94L8nVdw6tZxQA09x1Nen5+emp4Wr0g32x90+3bAEOtAvukjpp5rotvdwGTY+A2HgwAP9ccfL/4YRMoeOWBrc4nk5lhUF52/7jJflpMk7cB5fZkNJrPJ7PP/Ho5vErwBhkMerPhcIiac+rlOPhlo1og7kxGQwxUr7j0vCS/nTAitM5kPh+NGnxm6H7++35Jtp2e4qizyWTONeVwsPmfpw4rXfoKzNIZ1+ze//vQ72Zx7Eu6U/HRSOSBgaR5LEkOry5mcZJ6WcVH+6PguXmKXFF0w9iXC6vQLm+RNMvztXfL8xFwofPIXxjkfsh+fhcXAAAAAElFTkSuQmCC" alt="">
                </div>
            </div>
        </div>
        <div class="privilege-buy">
            <div class="title">
                <img src='../assets/images/privilege-buy-title.png' />
            </div>
            <p class="title-sub-text">大额神券+返现红包，最高可省近千元，每年预计可省10660元</p>
            <div class="buy-list w">
                <div class="buy-item">
                    <img src='../assets/images/pri-buy-img1.png' />
                    <div class="pri-scan-bg">
                        <div class="qrcode">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </div>
                </div>
                <div class="buy-item">
                    <img src='../assets/images/pri-buy-img2.png' />
                    <div class="pri-scan-bg">
                        <div class="qrcode">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </div>
                </div>
                <div class="buy-item">
                    <img src='../assets/images/pri-buy-img3.png' />
                    <div class="pri-scan-bg">
                        <div class="qrcode">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </div>
                </div>
                <div class="buy-item">
                    <img src='../assets/images/pri-buy-img4.png' />
                    <div class="pri-scan-bg">
                        <div class="qrcode">
                            <img src="../assets/images/scan-wx.png" alt="">
                            <p>扫码领取优惠</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="optimum-section">
            <div class="title">
                <img src="../assets/images/optimum-title-bg.png" alt="">
            </div>
            <div class="optimum-tab w">
                <div class="optimum-tab-list">
                    <div class="optimum-tab-item B-tab-item active">
                        精选
                    </div>
                    <div class="optimum-tab-item B-tab-item">
                        品牌美食
                    </div>
                    <div class="optimum-tab-item B-tab-item">
                        生活健康
                    </div>
                    <div class="optimum-tab-item B-tab-item">
                        交通出行
                    </div>
                </div>
                <div class="optimum-tab-contents">
                    <div class="optimum-tab-panel">
                        <div class="optimum-list">
                            <img src="../assets/images/opt-o-img1.png" alt="">
                            <img src="../assets/images/opt-o-img2.png" alt="">
                            <img src="../assets/images/opt-o-img3.png" alt="">
                            <img src="../assets/images/opt-o-img4.png" alt="">
                            <img src="../assets/images/opt-o-img5.png" alt="">
                            <img src="../assets/images/opt-o-img6.png" alt="">
                            <img src="../assets/images/opt-o-img7.png" alt="">
                            <img src="../assets/images/opt-o-img8.png" alt="">
                            <img src="../assets/images/opt-o-img9.png" alt="">
                            <img src="../assets/images/opt-o-img10.png" alt="">
                        </div>
                    </div>
                    <div class="optimum-tab-panel fn-hide">
                        <div class="optimum-list">
                            <img src="../assets/images/opt-t-img1.png" alt="">
                            <img src="../assets/images/opt-t-img2.png" alt="">
                            <img src="../assets/images/opt-t-img3.png" alt="">
                            <img src="../assets/images/opt-t-img4.png" alt="">
                            <img src="../assets/images/opt-t-img5.png" alt="">
                            <img src="../assets/images/opt-t-img6.png" alt="">
                            <img src="../assets/images/opt-t-img7.png" alt="">
                            <img src="../assets/images/opt-t-img8.png" alt="">
                            <img src="../assets/images/opt-t-img9.png" alt="">
                            <img src="../assets/images/opt-t-img10.png" alt="">
                        </div>
                    </div>
                    <div class="optimum-tab-panel fn-hide">
                        <div class="optimum-list">
                            <img src="../assets/images/opt-th-img1.png" alt="">
                            <img src="../assets/images/opt-th-img2.png" alt="">
                            <img src="../assets/images/opt-th-img3.png" alt="">
                            <img src="../assets/images/opt-th-img4.png" alt="">
                            <img src="../assets/images/opt-th-img5.png" alt="">
                            <img src="../assets/images/opt-th-img6.png" alt="">
                            <img src="../assets/images/opt-th-img7.png" alt="">
                        </div>
                    </div>
                    <div class="optimum-tab-panel fn-hide">
                        <div class="optimum-list">
                            <img src="../assets/images/opt-f-img1.png" alt="">
                            <img src="../assets/images/opt-f-img2.png" alt="">
                            <img src="../assets/images/opt-f-img3.png" alt="">
                            <img src="../assets/images/opt-f-img4.png" alt="">
                            <img src="../assets/images/opt-f-img5.png" alt="">
                            <img src="../assets/images/opt-f-img6.png" alt="">
                            <img src="../assets/images/opt-f-img7.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <p class="bottom-title">更多特权正在上线中···</p>
        </div>
        <div class="group-section">
            <div class="title">
                <img src="../assets/images/group-title-bg.png" alt="">
            </div>
            <p class="title-sub-text">八大人群都在趣买手，等你……</p>
            <div class="group-list w">
                <img src="../assets/images/group-img1.png" alt="">
                <img src="../assets/images/group-img2.png" alt="">
                <img src="../assets/images/group-img3.png" alt="">
                <img src="../assets/images/group-img4.png" alt="">
                <img src="../assets/images/group-img5.png" alt="">
                <img src="../assets/images/group-img6.png" alt="">
                <img src="../assets/images/group-img7.png" alt="">
                <img src="../assets/images/group-img8.png" alt="">
            </div>
        </div>
        <footer class="footer">
            <div class="w-middle">
                <div class="foot-content">
                    <div class="btm-item">
                        <h4>【关于我们】</h4>
                        <p>公司名称：上海巨潮信息技术有限公司</p>
                        <p>公司地址：上海市虹口区四川北路街道1318号</p>
                        <div class="auth_mark_list">
                          <a class="a_m_item" target="_blank" href="https://beian.miit.gov.cn/">
                              <img class="a_m_img" src="../assets/images/e_license_img.png">
                              <span style="margin-top: 0;margin-left: 10px;">备案号：沪ICP备19047532号-1</span>
                          </a>
                          <a class="a_m_item" target="_blank" :href="license_url">
                            <img class="electronic_license_img" src="../assets/images/electronic_license.jpg">
                            <span style="margin-top: 10px;margin-left: 10px;">营业执照</span>
                          </a>
                        </div>
                    </div>
                    <div class="btm-item">
                        <h4>【联系我们&商务合作】</h4>
                        <p>客服电话：021-61425123</p>
                        <p>商务合作邮箱：yinquanwen@apass.cn</p>
                        <!-- <p>商务合作电话：17702112742</p> -->
                    </div>
                    <div class="dl-item">
                        <img src="../assets/images/download-wx.png" alt="">
                        <p>扫一扫下载APP</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
      return {
        license_url: 'http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020200903085525000006282612-SAIC_SHOW_310000-2c9be8bd71c0288d0171cb6359f10b80506&signData=MEUCIQCYaN+PcKz787+06LKPg1CoG8AQ4Te/CN6JXoLWCo+7cQIgWNOCbrUaohVTA7ZkBuIcvQekmJzbSN3dW++emf75mrU='
      }
    },
    mounted () {
        $('.B-tab-item').click(function(){
            $(this).addClass('active').siblings().removeClass('active')
            $('.optimum-tab-panel').eq($(this).index()).removeClass('fn-hide').siblings().addClass('fn-hide')
        })
        $('.B-btn-box').hover(function(){
            $(this).find('.B-qr-code-box').removeClass('fn-hide')
        }, function(){
            $(this).find('.B-qr-code-box').addClass('fn-hide')
        })
    }
}
</script>

<style>
.banner {
    width: 100%;
    height: 980px;
    position: relative;
    background: url('../assets/images/banner_bg.png') no-repeat 50%;
}
.handle{
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: rgba(127,102,75,.4);
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.handle-tit-bg{
    width: 438px;
    height: 29px;
}
.handle-btn-box{
    position: relative;
    width: 324px;
    height: 54px;
}
.handle-btn{
    width: 324px;
    height: 54px;
    background-color: #FFCA68;
    border-radius: 8px;
    font-size: 28px;
    font-weight: 400;
    color: #634409;
    text-align: center;
    line-height: 54px;
    cursor: pointer;
}
.qr-code-box{
    width:124px;
    height:140px;
    position:absolute;
    left:56%;
    top: 76px;
    background-color:#fff4d4;
    margin-left:-68px;
    border-radius:8px;
    box-shadow:0 2px 20px rgba(0,0,0,.2);
    padding:13px 13px 29px;
    box-sizing:border-box;
    z-index: 2;
}
.qr-code-box img{
    width:100%
}
.qr-code-box:before{
    content:"";
    display:block;
    position:absolute;
    left:50%;
    top:-8px;
    -webkit-transform:translate(-50%);
    transform:translate(-50%);
    width:0;
    height:0;
    border-style:solid;
    border-width:0 11.365px 13.24px;
    border-color:transparent transparent #fff4d3
}
.qr-code-box .tips-title{
    color:#634409;
    font-size:16px;
    position:absolute;
    left:0;
    bottom:6px;
    text-align:center;
    line-height:22px;
    width:100%
}

.card-section{
    width: 100%;
    background-color: #262525;
    padding-bottom: 80px;
}
.card-top-bg{
    width: 100%;
    height: 460px;
    position: relative;
    background: url('../assets/images/card_bg.png') no-repeat 50%;
    background-size: cover;
}
.card-info-text{
    position: absolute;
    left: 49%;
    top: 145px;
}
.card-info-text p{
    width: 623px;
    font-size: 26px;
    color: #F5D499;
}

.card-box{
    width: 1260px;
    margin: 30px auto 0;
    background-color: #101010;
    border-radius: 24px;
    padding-top: 50px;
    padding-bottom: 30px;
}
.card-tit-bg{
    width: 688px;
    height: 49px;
}
.title-sub-text{
    margin-top: 14px;
    opacity: 0.7;
    font-family: PingFangSC-Regular;
    font-size: 24px;
    color: #FCD282;
    text-align: center;
}
.card-rights-list{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 35px 25px;
}
.card-rights-item{
    width: 347px;
    height: 544px;
    background-image: url('../assets/images/rights_bg.png');
    position: relative;
    text-align: center;
}
.rights-tit-bg{
    width: 194px;
    height: 23px;
    margin-top: 28px;
}
.rights-box{
    width: 324px;
    height: 432px;
    border-radius: 16px;
    background-color: #3F3838;
    margin-top: 45px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rts-item{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22px 0;
}
.rts-icon{
    width: 80px;
    height: 80px;
    margin-right: 30px;
}
.rts-info{
    width: 145px;
    text-align: left;
}
.rts-text{
    font-size: 24px;
    color: #FDE4C7;
}
.rts-btn{
    width: 117px;
    height: 35px;
    line-height: 35px;
    border-radius: 26px;
    text-align: center;
    font-size: 20px;
    color: #242424;
    background-image: linear-gradient(135deg, #E8C28F 0%, #FEE3BF 56%, #E4BD88 100%);
}

.shop-section {
    width: 100%;
    background-color: #2b2b2b;
    position: relative;
    padding-top: 80px;
}
.shop-content {
    width: 1260px;
    padding-top: 60px;
    padding-bottom: 85px;
    box-sizing: border-box;
    background: #101010;
    border-radius: 24px;
}

.shop-content .privi-big {
    width: 762px;
    margin: 60px auto 0;
    font-size: 0;
}

.shop-content .privi-big .item {
    width: 252px;
    height: 252px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: 1px solid #FCD282;
    border-radius: 16px;
}

.shop-content .privi-big .item .scan-bg{
    width: 251px;
    height: 251px;
    background-color: rgba(250, 221, 182, 0.2);
    border-radius: 14px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}

.shop-content .privi-big .item:hover .scan-bg{
    display: inherit;
}
.shop-content .privi-big .item .scan-bg img{
    margin-top: 25px;
    margin-left: 35px;
}
.shop-content .privi-big .item .scan-bg p{
    margin-top: 10px;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
}

.shop-content .privi-big .item:first-child:after,.shop-content .privi-big .item:nth-child(2):after,.shop-content .privi-big .item:nth-child(4):after,.shop-content .privi-big .item:nth-child(5):after {
    width: 4px;
    height: 4px;
    content: "";
    background-color: #838383;
    border-radius: 50%;
    background-size: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    -webkit-transform: translate3d(-25%,-25%,0);
    transform: translate3d(-25%,-25%,0);
    z-index: 1;
}

.shop-content .privi-big .item .img-box {
    width: 66px;
    height: 66px;
    margin: 63px auto 15px;
}

.shop-content .privi-big .item .img-box img {
    width: 100%;
    height: 100%
}

.shop-content .privi-big .item span {
    width: 100%;
    font-size: 18px;
    opacity: 0.7;
    color: #FFE0A6; 
    display: inline-block;
    text-align: center;
}
.shop-content .privi-big .item span.pre-txt{
    font-size: 16px;
    color: #FFE0A6;
    opacity: 0.4;
    margin-top: 12px;
}

.shop-section .decorate-box {
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: -30px;
    position: absolute
}

.shop-section .decorate-box .img-box {
    position: absolute;
    display: inline-block
}

.shop-section .decorate-box .img-box.img-box-4 {
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: -30px
}

.shop-section .decorate-box .img-box img {
    width: 100%;
    height: 100%
}

.privilege-buy{
    background-color: #2b2b2b;
    padding-top: 50px;
    padding-bottom: 80px;
}
.buy-list{
    margin-top: 45px;
    width: 1260px;
    display: flex;
    justify-content: space-between;
}
.privilege-buy .buy-list .buy-item{
    position: relative;
}
.privilege-buy .buy-list .buy-item .pri-scan-bg{
    width: 300px;
    height: 500px;
    background-color: rgba(255,202,104, 0.3);
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}
.privilege-buy .buy-list .buy-item:hover .pri-scan-bg{
    display: inherit;
}

.privilege-buy .buy-list .buy-item .pri-scan-bg .qrcode{
    margin-top: 50px;
    margin-left: 25px;
    width: 250px;
    height: 300px;
    background: #FFD079;
    border-radius: 8px;
}

.privilege-buy .buy-list .buy-item .pri-scan-bg .qrcode img{
    margin-top: 25px;
    margin-left: 35px;
}
.privilege-buy .buy-list .buy-item .pri-scan-bg .qrcode p{
    margin-top: 10px;
    font-size: 16px;
    color: #362401;
    text-align: center;
}

.optimum-section{
    background-color: #0f0f0f;
    padding-top: 70px;
    padding-bottom: 80px;
}
.optimum-tab{
    margin-top: 45px;
    width: 1260px;
}
.optimum-tab-list{
    display: flex;
    justify-content: space-between;
}
.optimum-tab-item{
    width: 260px;
    height: 80px;
    line-height: 80px;
    border: 2px solid #FFCA68;
    border-radius: 8px;
    text-align: center;
    font-size: 26px;
    color: #FCD282;
    cursor: pointer;
}
.optimum-tab-item.active{
    background-color: #FFCA68;
    color: #453007;
}
.optimum-tab-contents{
    margin-top: 40px;
}
.optimum-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.optimum-list img{
    margin: 0 15px 30px 15px;
}
.bottom-title {
    font-size: 28px;
    color: #ddc47d;
    text-align: center;
    margin-top: 10px;
}

.group-section{
    padding-top: 70px;
    padding-bottom: 60px;
}
.group-list{
    margin-top: 42px;
    width: 1260px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.group-list img{
    margin-bottom: 20px;
}

.footer{
    width: 100%;
    height: 300px;
    background-color: #101010;
}
.foot-content{
    height: 100%;
    display: flex;
    align-items: center;
}
.btm-item{
    margin-left: 120px;
}
.btm-item:nth-child(2){
    margin-left: 150px;
}
.btm-item h4{
    font-size: 20px;
    color: #FADDB6;
    position: relative;
    margin-bottom: 40px;
}
.btm-item h4:after{
    content: "";
    width: 70px;
    height: 4px;
    background-color: rgba(250, 221, 182, 0.7);
    position: absolute;
    top: 40px;
    left: 10px;
}
.btm-item p{
    font-size: 16px;
    color: #FADDB6;
    opacity: 0.8;
    margin-left: 10px;
    margin-top: 10px;
}
.btm-item a, .btm-item a:visited{
    font-size: 16px;
    color: #FADDB6;
} 
.btm-item a:hover{
    color: #FFC474;
}
.dl-item{
    margin-left: 140px;
}
.dl-item p{
    margin-top: 12px;
    font-size: 16px;
    color: #FADDB6;
}
.auth_mark_list{
    margin-top: 10px;
}
.a_m_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a_m_img {
  display: block;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
.electronic_license_img {
  margin-top: 10px;
  margin-left: 10px;
  display: block;
  width: 20px;
  height: 20px;
}
</style>