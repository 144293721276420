<template>
    <div class="fixed-head B-fixed-head">
        <div class="fixed-icon-box clearfix w-middle">
            <span class="left logo fl"></span>
            <div class="right fr B-slide-list">
                <ul class="list-box B-list-box">
                    <li>
                        <router-link to='/'>
                            趣买手首页
                            <span></span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to='/online-apply'>
                            在线申请
                            <span></span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to='/privilege'>                        
                            优惠特权
                            <span></span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to='/download'>           
                            下载APP
                            <span></span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to='/contact-us'>
                            联系我们&商务合作
                            <span></span>
                        </router-link>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<script>
let navIndex = {
    '/': 0,
    '/online-apply': 1,
    '/privilege': 2,
    '/download': 3,
    '/contact-us': 4
}
export default {
    name: 'BHeader',
    mounted () {
        let curIndex = navIndex[location.pathname]
        $('.B-list-box li').eq(curIndex).addClass('active')
        $('.B-list-box li').hover(
            function () {
                $(this).addClass('hover');
            },
            function () {
                $(this).removeClass('hover');  
            }
        )
        $('.B-list-box li').click(function(){
            if (!$(this).hasClass('active')) {
                $(this).addClass('active').siblings().removeClass('active');
            }        
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fixed-head{
    height: 100px;
    background-color: #303030;
    position:absolute;
    top:0;
    right:0;
    left:0;
    -webkit-transition:all 1s;
    transition:all 1s;
    z-index:9999;
}
.fixed-icon-box{
    height: 100%;
    padding: 0 10px;
    box-sizing:border-box;
}
.fixed-icon-box .logo{
    width: 126px;
    height: 40px;
    margin:33px 0 23px;
    background-image: url('../assets/images/logo.png');
}
.fixed-icon-box .list-box,.fixed-icon-box .right{
    height:100%
}
.fixed-icon-box .list-box li{
    height:100%;
    line-height: 100px;
    display:inline-block;
    padding:0 16px;
    margin-left:18px;
    position:relative;
}
.fixed-icon-box .list-box li a{
    display:block;
    font-size:16px;
    color:#929292;
    text-decoration:none;
    position:relative
}
.fixed-icon-box .list-box li a span{
    position:absolute;
    left: 50%;
    width: 9px;
    bottom: 10px;
    height: 0px;
    font-weight: 400;
    margin-left: -4.5px;
    background-image: url('../assets/images/nav_active_bg.png');
    -webkit-transition: height .5s;
    transition: height .5s;
}
.fixed-icon-box .list-box li.active a{
    color:#FFCA68;
}
.fixed-icon-box .list-box li.active a span{
    height: 26px;
}
.fixed-icon-box .list-box li.hover a{
    color:#FFCA68;
}
.fixed-icon-box .list-box li.hover a span{
    height: 26px;
}
</style>
